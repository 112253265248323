import React from "react"
import { Link, useStaticQuery, graphql, navigate } from "gatsby"
import Img from "gatsby-image"
import { Navbar, Hamburger, NavModal } from "./styled/layout"
import { Button, LocationModal } from "./styled/components"

const Header = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      file(relativePath: { eq: "wellhealth-logo.png" }) {
        childImageSharp {
          fixed(width: 190, height: 32) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const [sidebar, setSidebar] = React.useState(false)
  const [modal, setModal] = React.useState(false)
  const [modal2, setModal2] = React.useState(false)

  return (
    <Navbar sidebar>
      <div className="logo">
        <Link to="/" aria-label="Home">
          <Img fixed={data.file.childImageSharp.fixed} />
        </Link>
      </div>
      <div className="mobile" onClick={() => setSidebar(!sidebar)}>
        <Hamburger role="button" />
      </div>
      {sidebar && (
        <NavModal>
          <Hamburger role="button" open onClick={() => setSidebar(!sidebar)} />
          <div className="links">
            <Link css={{ paddingLeft: "19px" }} to="/about/">
              About Us
            </Link>
            
            <div style={{ paddingLeft: "19px" }} to="/services/">
              <Button
                className="location"
                onMouseEnter={() => setModal2(true)}
                onMouseLeave={() => setModal2(false)}
                onClick={() => navigate('/services/')}
                onFocus={() => setModal2(true)}
              >
                Services
              </Button>
              {modal2 && (
                <LocationModal
                  onMouseEnter={() => setModal2(true)}
                  onMouseLeave={() => setModal2(false)}
                >
                  <ul>
                    <Link to="https://www.gogettested.com/">
                      <li>Schedule Now</li>
                    </Link>
                    <Link to="/well-to-work/">
                      <li>Well to Work</li>
                    </Link>
                  </ul>
                </LocationModal>
              )}
            </div>

            <Link css={{ paddingLeft: "19px" }} to="/covid-19/">
              COVID-19
            </Link>
            <Link css={{ backgroundColor: "#ECC048", paddingLeft: "19px" }} to="/well-to-life/">
              Well to Life
            </Link>
            <Link css={{ paddingLeft: "19px" }} to="/news/">
              News
            </Link>
            <Link css={{ paddingLeft: "19px" }} to="/blog/">
              Blog
            </Link>
            <Link css={{ paddingLeft: "19px" }} to="/faqs/">
              FAQs
            </Link>
            <Link css={{ paddingLeft: "19px" }} to="/contact/">
              Contact Us
            </Link>
          </div>
        </NavModal>
      )}
      <div className="desktop">
        <Link to="/about/">About Us</Link>
        <Link 
          to="/services/"
          onMouseEnter={() => setModal(true)}
          onMouseLeave={() => setModal(false)}
          onFocus={() => setModal(true)}
        >
          Services
        </Link>
        {modal && (
          <LocationModal
            onMouseEnter={() => setModal(true)}
            onMouseLeave={() => setModal(false)}
            translation="-248px"
            >
            <ul>
              <Link to="https://www.gogettested.com/">
                <li>Schedule Now</li>
              </Link>
              <Link to="/well-to-work/">
                <li>Well to Work</li>
              </Link>
            </ul>
          </LocationModal>
        )}
        <Link to="/covid-19/">COVID-19</Link>
        <Link style={{ backgroundColor: "#ECC048" }} to="/well-to-life/">Well to Life</Link>
        <Link to="/news/">News</Link>
        <Link to="/blog/">Blog</Link>
        <Link to="/faqs/">FAQs</Link>
        <Link to="/contact/">Contact Us</Link>
      </div>
    </Navbar>
  )
}

export default Header
