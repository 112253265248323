import { createGlobalStyle } from "styled-components"
import Theme, { xs, sm } from "./theme"

const GlobalStyleInjector = createGlobalStyle`

  @import url("https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@700&display=swap");
  @import url("https://fonts.googleapis.com/css2?family=Baskervville&display=swap");
  body {
    font-family: Gotham, sans-serif;
    color: ${Theme.palette.text.primary};
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: ${Theme.palette.font.header};
    margin: 0;
    padding: 0;
  }
  h2 {
    font-size: 52px;
    ${xs} {
      font-size: 42px;
    }
  }
  a {
    text-decoration: none;
    color: ${Theme.palette.text.primary};
  }
  p {
    line-height: 200%;
  }
  .slick-next {
    position: absolute;
    right: 0;
    transform: scale(3) translate(-50%);
    
    border-radius: 50%;
    z-index: 5;
    ::before {
      color: ${Theme.palette.text.primary} !important;
    }
  }
  .slick-prev {
    position: absolute;
    left: 0;
    transform: scale(3) translate(50%);
    z-index: 4;
    ::before {
      color: ${Theme.palette.text.primary} !important;
    }
  }
  ${xs} {
    .slick-next {
      transform: scale(1.5) translate(-25%);
    }
    .slick-prev {
      transform: scale(1.5) translate(25%);
    }
  }
  .accordion {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      border-left: none;
      border-right: none;
  }
  .accordion__item + .accordion__item {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .accordion__button {
      background-color: ${Theme.palette.background};
      color: ${Theme.palette.text.primary};
      cursor: pointer;
      padding: 28px 0;
      width: 100%;
      text-align: left;
      border: none;
      font-size: 22px;
      font-weight: bold;    
  }
  .accordion__button:hover {
      background-color: #f4f4f4;
  }
  .accordion__button:before {
      display: inline-block;
      content: '';
      height: 10px;
      width: 10px;
      margin-right: 12px;
      transform: rotate(-45deg);
  }
  .accordion__button[aria-expanded='true']::before,
  .accordion__button[aria-selected='true']::before {
      transform: rotate(45deg);
  }
  .accordion__panel {
      padding: 20px;
      animation: fadein 0.35s ease-in;
  }
  ${xs} {
    .accordion__button {
      font-size: 20px;
    }
  }

  /* Covid Corporate Response Page */
  .wellToWork {
    margin: 0;
    padding-top: 0;
    height: 75vh;
    width: 100%;
    color: white;
    background: linear-gradient(rgba(44, 62, 80, 1), rgba(44, 62, 80, 0.5)), url(/images/COVID_Corporate_Response.jpg) center;
    background-size: cover;
    .header {
      padding: 0 50px;
      h1 {
        font-size: 48px;
      }
    }
    .form {
      width: 70%;
      margin: 0 auto;
      padding: 0 50px;
      h3 {
        color: ${Theme.palette.text.primary};
      }
      button {
        width: 30%;
        display: block;
        margin: auto;
      }
      input::placeholder {
        color: gray;
      }
      .select {
        margin: 0 auto;
        select {
          &:invalid {
            color: gray;
          }
        }
      }
      ${sm} {
        h3 {
          font-size: 20px;
        }
        margin-bottom: 40px;
      }
      ${xs} {
        width: 70%;
        input {
          display: block;
          width: 70%;
          margin: 10px auto;
        }
        select {
          display: block;
          width: 80%;
          margin: 10px auto;
        }
      }
    }
    ${sm} {
      height: 100vh;
      text-align: center;
      .header {
        padding: 50px;
      }
    }
    ${xs} {
      height: 120vh;
      .header {
        h1 {
          font-size: 32px;
        }
        p {
          font-size: 16px;
        }
      }
    }
  }
  .testingMap {
    section {
      overflow: visible;
      h1 {
        font-size: 48px !important;
        z-index: 1;
      }
      img {
        margin: auto;
        width: 70%;
        margin-top: -170px;
      }
    }
    ${sm} {
      section {
        h1 {
          font-size: 36px !important;
        }
        img {
          width: 80%;
          margin-top: -140px;
        }
      }
    }
    ${xs} {
      section {
        margin-top: 50px;
        h1 {
          font-size: 24px !important;
        }
        img {
          width: 90%;
          margin-top: -90px;
        }
      }
    }
  }
  .testingPartners {
    h1 {
      font-size: 48px;
      margin: auto;
      padding: 10px 0;
      width: 60%;
      position: relative;
      top: 130px;
      color: white;
      background-color: ${Theme.palette.text.primary};
    }
    h3 {
      font-size: 24px;
    }
    .partners {
      margin: auto;
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .corporate {
      padding: 200px 0 50px 0;
      .corporateRow {
        padding: 10px 20px;
        display: grid;
        div {
          margin: auto;
        }  
        img {
          max-width: 150px;
        }
      }
      .row1 {
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 50px;
      }
      .row2 {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 70px;
      }
    }
    .stateGov {
      padding: 200px 0 50px 0;
      vertical-align: top;
      background-color: ${Theme.palette.backgroundAlt};
      table {
        margin: auto;
        border-spacing: 80px 20px;
        font-size: 24px;
      }
    }
    ${sm} {
      h1 {
        margin-top: 50px;
        font-size: 36px;
        padding: 10px 10px;
        position: static;
      }
      .partners {
        display: block;
      }
      .corporate {
        padding: 50px 0;
      }
      .stateGov {
        padding: 50px 0;
      }
    }
    ${xs} {
      h1 {
        font-size: 24px;
      }
      h3 {
        font-size: 20px;
      }
      .corporate .corporateRow img {
        max-width: 75px;
      }
      .stateGov table {
        border-spacing: 40px 20px;
        font-size: 16px;
      }
    }
  }
  .testingExperience {
    h1 {
      font-size: 48px;
    }
    table {
      margin: 60px auto;
      width: 90%;
      border-spacing: 20px;
      text-align: center;
      table-layout: fixed;
      img {
        max-width: 60px;
      }
    }
    ${sm} {
      h1 {
        font-size: 36px;
      }
    }
    ${xs} {
      h1 {
        font-size: 24px;
      }
      img {
        max-width: 30px !important;
      }
      span {
        font-size: 12px;
      }
    }
  }
  .testingFeatures {
    width: 70%;
    padding: 20px 0;
    margin: auto;
    h1 {
      font-size: 48px;
    }
    hr {
      float: left;
      width: 20%;
      border-radius: 5px;
      border: 2px solid ${Theme.palette.text.primary};
    }
    ul {
      font-size: 24px;
      li {
        margin: 20px 0;
      }
    }
    ${sm} {
      h1 {
        font-size: 36px;
      }
      ul {
        font-size: 20px;
      }
    }
    ${xs} {
      width: 80%;
      h1 {
        font-size: 24px;
      }
      hr {
        border: 1px solid ${Theme.palette.text.primary};
      }
      ul {
        font-size: 16px;
      }
    }
  }
  .responseUnits {
    width: 70%;
    padding: 20px 0;
    margin: 50px auto;
    color: white;
    background: url(/images/Response_Units.png) center;
    background-size: cover;
    h1 {
      font-size: 48px;
    }
    p {
      font-size: 32px;
    }
    div {
      width: 80%;
      margin: auto;
    }
    hr {
      float: left;
      width: 20%;
      border-radius: 5px;
      border: 2px solid white;
    }
    ${sm} {
      h1 {
        font-size: 36px;
      }
      p {
        font-size: 24px;
      }
    }
    ${xs} {
      width: 80% !important;
      h1 {
        font-size: 24px;
      }
      p {
        font-size: 16px;
      }
      hr {
        border: 1px solid white;
        margin-bottom: 5px;
      }
    }
  }
  .corporateContact {
    width: 90%;
    padding: 20px 0;
    margin: 50px auto;
    .contactOptions {
      width: 90%;
      margin: 20px auto;
      ${sm} {
        display: block;
      }
      ${xs} {
        width: 100%;
      }
    }
    div {
      width: 80%;
      margin: auto;
    }
    h1 {
      width: 50%;
      display: block;
      margin: auto;
      font-size: 36px;
    }
    img {
      display: block;
      margin: 40px auto;
      max-width: 200px;
    }
    table {
      font-size: 24px;
      margin: auto;
      text-align: center;
      border-spacing: 10px;
    }
    a {
      color: ${Theme.palette.button.primary};
    }
    ${sm} {
      h1 {
        width: 70%;
      }
    }
    ${xs} {
      div {
        width: 90% !important;
      }
      h1 {
        width: 80%;
        font-size: 24px;
      }
      img {
        max-width: 150px;
      }
      table {
        font-size: 16px;
      }
    }
  }
  .corporatePromo {
    width: 70%;
    padding: 20px 0;
    margin: 50px auto;
    div {
      width: 80%;
      margin: auto;
    }
    h1 {
      font-size: 48px;
    }
    p {
      font-size: 24px;
    }
    ${sm} {
      h1 {
        font-size: 36px;
      }
      p {
        font-size: 20px;
      }
    }
    ${xs} {
      width: 100%;
      h1 {
        font-size: 24px;
      }
      p {
        max-width: 90%;
        margin: 20px auto;
        font-size: 16px;
      }
    }
  }
  .locationsPage {
    height: 100vh;
    table {
      border-spacing: 0 20px;
      max-width: 90%;
    }
    ${sm} {
      height: 190vh;
      table {
        margin: auto;
        border-spacing: 0 10px;
      }
    }
  }
  .mapMarker {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90px;
    height: 90px;
    border-radius: 100%;
    transform: translate(-50%, -90%);
    img {
      object-fit: contain;
    }
  }
  .services-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    padding-bottom: 20px;
    padding-top: 0px !important;
    a {
      font-size: 20px;
      font-weight: bold;
    }
    @media only screen and (max-width: 960px) {
      display: flex;
      flex-direction: column;
    }
  }
  .services-content {
    display: flex;
    flex-direction: column;
    padding: 15px;
    img {
      padding-bottom: 20px;
    }
  }
  /* -------------------------------------------------- */
  /* ---------------- Animation part ------------------ */
  /* -------------------------------------------------- */
  
  @keyframes fadein {
      0% {
          opacity: 0;
      }
  
      100% {
          opacity: 1;
      }
  }
`

export default GlobalStyleInjector
