import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import {
  FooterContainer,
  FooterCopyrightSection,
  FooterMenuSection,
} from "./styled/layout"
import Img from "gatsby-image"
import { Facebook } from "@styled-icons/fa-brands/Facebook"
import { Twitter } from "@styled-icons/fa-brands/Twitter"
import { Instagram } from "@styled-icons/fa-brands/Instagram"
import { Linkedin } from "@styled-icons/fa-brands/Linkedin"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      file(relativePath: { eq: "wellhealth-logo-white.png" }) {
        childImageSharp {
          fixed(width: 190, height: 32) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <FooterContainer>
      <FooterMenuSection>
        <div>
          <h4>Healthcare</h4>
          <ul>
            <li>
              <Link to="/services/">Services</Link>
            </li>
            <li>
              <Link to="/covid-19/">COVID-19</Link>
            </li>
            <li>
              <Link to="/well-to-life/">Well to Life</Link>
            </li>
          </ul>
        </div>
        <div>
          <h4>Community</h4>
          <ul>
            <li>
              <Link to="/blog/">Blog</Link>
            </li>
            <li>
              <a href="https://www.facebook.com/wellhealthfrisco/">
                <Facebook size={24} />
              </a>
              <a href="https://twitter.com/WellhealthF">
                <Twitter size={24} />
              </a>
              <a href="https://www.instagram.com/wellhealthdfw">
                <Instagram size={24} />
              </a>
              <a href="https://www.linkedin.com/company/wellhealth-studio">
                <Linkedin size={24} />
              </a>
            </li>
          </ul>
        </div>
        <div>
          <h4>Support</h4>
          <ul>
            <li>
              <a href="tel:+12818176060">
                Call us at (281) 817-6060
              </a>
            </li>
            <li>
              <Link to="/contact/">Contact Us</Link>
            </li>
            <li>
              <Link to="/faqs/">FAQs</Link>
            </li>
          </ul>
        </div>
      </FooterMenuSection>
      <FooterCopyrightSection>
        <div>
          <Img fixed={data.file.childImageSharp.fixed} />
          <p>
            © {new Date().getFullYear()}, WellHealth, LLC. All rights reserved.
          </p>
        </div>
      </FooterCopyrightSection>
    </FooterContainer>
  )
}

export default Footer
